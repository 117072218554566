// src/components/ProductLookup.js
import React, { useState } from 'react';
import {
  Box,
  Button,
  Typography,
  CircularProgress,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from '@mui/material';
import BarcodeScannerComponent from './BarcodeScanner';
import useProductLookup from '../hooks/useProductLookup';

// Helper function to format the date
const formatDate = (timestamp) => {
  const date = new Date(timestamp);
  return date.toLocaleDateString(); // Adjust to your preferred date format
};

function ProductLookup() {
  const { productInfo, priceHistory, loading, error, lookupProduct } = useProductLookup();
  const [scanning, setScanning] = useState(true);

  const handleProductScanned = (ean) => {
    setScanning(false);
    lookupProduct(ean);
  };

  const handleRescan = () => {
    setScanning(true);
  };

  // Extract product details if productInfo is an array and not empty
  const productDetails = Array.isArray(productInfo) && productInfo.length > 0 ? productInfo[0] : null;

  return (
    <Box textAlign="center">
      {scanning ? (
        <BarcodeScannerComponent onProductScanned={handleProductScanned} />
      ) : (
        <Box>
          {loading ? (
            <CircularProgress />
          ) : error ? (
            <Typography color="error">{error}</Typography>
          ) : (
            <Box>
              {/* Product Information Table */}
              <Typography variant="h6" mt={2}>Product Information</Typography>
              {productDetails ? (
                <TableContainer component={Paper} sx={{ mt: 2 }}>
                  <Table aria-label="product information table">
                    <TableHead>
                      <TableRow>
                        <TableCell>EAN</TableCell>
                        <TableCell>Product Description</TableCell>
                        <TableCell>Category</TableCell>
                        <TableCell>Brand</TableCell>
                        <TableCell>Product Weight (KG)</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      <TableRow>
                        <TableCell>{productDetails.EAN}</TableCell>
                        <TableCell>{productDetails.ProductDescription}</TableCell>
                        <TableCell>{productDetails.Category}</TableCell>
                        <TableCell>{productDetails.Brand}</TableCell>
                        <TableCell>{productDetails.ProductWeightKG}</TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </TableContainer>
              ) : (
                <Typography>No product data found.</Typography>
              )}

              {/* Price History Table */}
              <Typography variant="h6" mt={2}>Price History</Typography>
              {priceHistory && priceHistory.length > 0 ? (
                <TableContainer component={Paper} sx={{ mt: 2, maxHeight: 300, overflowY: 'auto' }}>
                  <Table stickyHeader aria-label="price history table">
                    <TableHead>
                      <TableRow>
                        <TableCell>Date</TableCell>
                        <TableCell>Store Name</TableCell>
                        <TableCell>Price</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {priceHistory.map((record, index) => (
                        <TableRow key={index}>
                          <TableCell>{formatDate(record.Date)}</TableCell>
                          <TableCell>{record.StoreName}</TableCell>
                          <TableCell>{record.Price}</TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              ) : (
                <Typography>No pricing history found.</Typography>
              )}
            </Box>
          )}
          <Button variant="contained" color="secondary" onClick={handleRescan} sx={{ mt: 2 }}>
            Scan Another Product
          </Button>
        </Box>
      )}
    </Box>
  );
}

export default ProductLookup;