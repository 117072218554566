// src/hooks/useSurvey.js
import useLocalStorage from './useLocalStorage';
import { fetchProduct, submitPricingData } from '../utils/surveyApi';

export default function useSurvey() {
  const [surveyStarted, setSurveyStarted] = useLocalStorage('surveyStarted', false);
  const [store, setStore] = useLocalStorage('store', null);
  const [scannedProducts, setScannedProducts] = useLocalStorage('scannedProducts', []);
  const [currentProduct, setCurrentProduct] = useLocalStorage('currentProduct', null);
  const [showTable, setShowTable] = useLocalStorage('showTable', false);
  const [scanning, setScanning] = useLocalStorage('scanning', true);

  const startNewSurvey = () => {
    setSurveyStarted(true);
    resetSurveyState();
  };

  const resetSurveyState = () => {
    setStore(null);
    setScannedProducts([]);
    setCurrentProduct(null);
    setShowTable(false);
    setScanning(true);
  };

  const handleComplete = async () => {
    try {
      await submitPricingData(scannedProducts);
      console.log("Pricing data submitted:", scannedProducts);
      setSurveyStarted(false);
      resetSurveyState();
    } catch (error) {
      console.error("Error during submission:", error);
    }
  };

  const handleStoreSelect = (selectedStore) => setStore(selectedStore);

  const handleProductScanned = async (barcode) => {
    if (!store) return;
    const productData = await fetchProduct(barcode, store);
    setCurrentProduct(productData);
  };

  const handleProductSave = (productWithPrice) => {
    const newProduct = {
      ...productWithPrice,
      LPStoreNumber: store.LPStoreNumber,
      StoreName: `${store.Retailer} - ${store.Name}`,
    };
  
    // Update scannedProducts directly with a new array to trigger local storage update
    setScannedProducts([...scannedProducts, newProduct]);
    setCurrentProduct(null);
    setShowTable(true);
    setScanning(true);
  };

  const handleScanAnother = () => {
    setShowTable(false);
    setCurrentProduct(null);
    setScanning(true);
  };

  return {
    surveyStarted,
    startNewSurvey,
    handleComplete,
    store,
    handleStoreSelect,
    handleProductScanned,
    handleProductSave,
    handleScanAnother,
    scannedProducts,
    currentProduct,
    showTable,
    scanning,
  };
}