// src/hooks/useProductLookup.js
import { useState } from 'react';
import axios from 'axios';

export default function useProductLookup() {
  const [productInfo, setProductInfo] = useState(null);
  const [priceHistory, setPriceHistory] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const lookupProduct = async (ean) => {
    setLoading(true);
    setError(null);

    try {
      // Fetch product information
      const productResponse = await axios.get(`${process.env.REACT_APP_API_PRODUCT}`, {
        params: { EAN: ean },
      });
      setProductInfo(productResponse.data);

      // Fetch price history
      const pricingResponse = await axios.get(`${process.env.REACT_APP_API_PRICING}`, {
        params: { EAN: ean },
      });
      setPriceHistory(pricingResponse.data);
    } catch (err) {
      setError("Error retrieving product or pricing information");
      console.error("Error:", err);
    } finally {
      setLoading(false);
    }
  };

  return {
    productInfo,
    priceHistory,
    loading,
    error,
    lookupProduct,
    setProductInfo,      // Add this line
    setPriceHistory,      // Add this line
  };
}