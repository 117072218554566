// src/components/BarcodeScanner.js
import React, { useEffect, useRef, useState } from 'react';
import Webcam from 'react-webcam';
import BarcodeDetector from 'barcode-detector'; // Import the barcode-detector polyfill

const BarcodeScannerComponent = ({ onProductScanned }) => {
  const webcamRef = useRef(null);
  const [scanning, setScanning] = useState(true);
  let barcodeDetector;

  useEffect(() => {
    // Initialize BarcodeDetector and check for support
    if ('BarcodeDetector' in window) {
      barcodeDetector = new window.BarcodeDetector({
        formats: ['ean_13', 'ean_8', 'upc_a', 'upc_e']
      });
    } else {
      console.error("BarcodeDetector is not supported in this browser.");
      return;
    }

    const scanBarcode = async () => {
      console.log("Starting barcode scan attempt...");

      if (webcamRef.current && scanning) {
        const imageSrc = webcamRef.current.getScreenshot();

        if (imageSrc) {
          console.log("Image captured for scanning:", imageSrc.substring(0, 50) + "...");

          try {
            const img = new Image();
            img.src = imageSrc;
            img.onload = async () => {
              try {
                const results = await barcodeDetector.detect(img);
                
                if (results.length > 0) {
                  console.log("Barcode detected:", results[0].rawValue);
                  onProductScanned(results[0].rawValue);
                  setScanning(false); // Stop scanning on success
                } else {
                  console.log("No barcode detected in this frame.");
                }
              } catch (error) {
                console.error("Error during barcode scan:", error.message);
              }
            };
          } catch (error) {
            console.error("Error loading image for barcode detection:", error.message);
          }
        } else {
          console.warn("Failed to capture image from webcam.");
        }
      } else {
        console.warn("Webcam reference not ready or scanning disabled.");
      }
    };

    const interval = setInterval(() => {
      scanBarcode();
    }, 250); // Adjust interval as needed

    return () => clearInterval(interval); // Cleanup interval on component unmount
  }, [scanning, onProductScanned]);

  const videoConstraints = {
    facingMode: 'environment',
  };

  return (
    <div className="d-flex justify-content-center align-items-center">
      <div className="camera-wrapper">
        <Webcam
          audio={false}
          ref={webcamRef}
          screenshotFormat="image/jpeg"
          videoConstraints={{ facingMode: 'environment' }}
          className="webcam"
        />
      </div>
    </div>
  );
};

export default BarcodeScannerComponent;