// src/components/ProductEntry.js
import React, { useState, useEffect } from 'react';
import { Box, Button, TextField } from '@mui/material';

const ProductEntry = ({ product, onSave }) => {
  const [Price, setPrice] = useState('');

  useEffect(() => {
    // Load any saved price for this product from local storage on component mount
    const savedPrice = localStorage.getItem(`price_${product.EAN}`);
    if (savedPrice) setPrice(savedPrice);
  }, [product.EAN]);

  const handlePriceChange = (e) => {
    const newPrice = e.target.value;
    setPrice(newPrice);
    // Save to local storage
    localStorage.setItem(`price_${product.EAN}`, newPrice);
  };

  const handleSave = () => {
    // Retrieve the price directly from local storage in case of Safari issues
    const priceValue = localStorage.getItem(`price_${product.EAN}`)
      ? parseFloat(localStorage.getItem(`price_${product.EAN}`))
      : null;

    onSave({
      ...product,
      Price: priceValue,
    });

    setPrice(''); // Clear price input after saving
    localStorage.removeItem(`price_${product.EAN}`); // Clear local storage after save
  };

  return (
    <Box display="flex" flexDirection="column" alignItems="center" gap={2}>
      <h2>{product.ProductDescription}</h2>
      <TextField
        label="Enter Price"
        variant="outlined"
        type="number"
        value={Price}
        onChange={handlePriceChange}  // Update to use handlePriceChange
        sx={{ width: '100%' }}
      />
      <Button 
        variant="contained" 
        color="primary" 
        size="large" 
        onClick={handleSave}
        disabled={!Price}  // Disable if no price entered
      >
        Save
      </Button>
    </Box>
  );
};

export default ProductEntry;