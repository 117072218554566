// src/components/LocationAndStore.js
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Box, Typography, List, ListItem, ListItemText, CircularProgress, Paper } from '@mui/material';

const LocationAndStore = ({ onSelectStore }) => {
  const [stores, setStores] = useState([]);
  const [loading, setLoading] = useState(true);
  const [searchPrompt, setSearchPrompt] = useState(false);

  useEffect(() => {
    const timeoutId = setTimeout(() => setSearchPrompt(true), 5000);

    navigator.geolocation.getCurrentPosition(async (position) => {
      const { latitude, longitude } = position.coords;
      try {
        const response = await axios.post(process.env.REACT_APP_API_LOCATIONS, {
          longitude,
          latitude,
          radius: 20,
          units: 'km',
        });
        setStores(response.data);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching stores:", error);
        setLoading(false);
      }
    });

    return () => clearTimeout(timeoutId);
  }, []);

  if (loading) {
    return (
      <Box display="flex" alignItems="center" justifyContent="center" mt={2}>
        <CircularProgress />
        <Typography variant="body1" ml={2}>Loading stores...</Typography>
      </Box>
    );
  }

  return (
    <Box>
      <Typography variant="h5" gutterBottom>Select a Store</Typography>
      <List>
        {stores.map((store) => (
          <Box key={store.LPStoreNumber} mb={2}>
            <Paper elevation={2}>
              <ListItem 
                button 
                onClick={() => onSelectStore(store)}
                sx={{ padding: 2 }}
              >
                <ListItemText primary={`${store.Retailer} - ${store.Name}`} />
              </ListItem>
            </Paper>
          </Box>
        ))}
      </List>
      {searchPrompt && stores.length === 0 && (
        <Typography variant="body2" color="textSecondary">
          No stores found in your area. Please try again later.
        </Typography>
      )}
    </Box>
  );
};

export default LocationAndStore;