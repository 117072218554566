// src/App.js
import React, { useState } from 'react';
import { Route, Routes, useNavigate, useLocation } from 'react-router-dom';
import { Box, Button, Container, IconButton } from '@mui/material';
import HomeIcon from '@mui/icons-material/Home';
import SurveyPage from './components/SurveyPage';
import ProductLookup from './components/ProductLookup';

function App() {
  const [showHomeIcon, setShowHomeIcon] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();

  const handleStartSurvey = () => {
    setShowHomeIcon(true);
    navigate('/survey');
  };

  const handleProductLookup = () => {
    setShowHomeIcon(true);
    navigate('/lookup');
  };

  const handleReset = () => {
    if (window.confirm("Are you sure you want to reset? This will clear all saved data.")) {
      localStorage.clear();
      window.alert("All data has been reset.");
    }
  };

  const goHome = () => {
    setShowHomeIcon(false);
    navigate('/');
  };

  return (
    <Container maxWidth="sm" sx={{ mt: 4, textAlign: 'center' }}>
      {location.pathname === '/' && (
        <Box component="img" src={`${process.env.PUBLIC_URL}/logo512.png`} alt="Logo" sx={{ width: '150px', mb: 2 }} />
      )}

      {/* Conditionally render either the initial buttons or the home icon */}
      {showHomeIcon ? (
        <IconButton onClick={goHome} color="primary" aria-label="home">
          <HomeIcon fontSize="large" />
        </IconButton>
      ) : (
        <Box display="flex" flexDirection="column" alignItems="center" gap={2}>
          <Button variant="contained" color="primary" size="large" onClick={handleStartSurvey}>
            Start New Price Survey
          </Button>
          <Button variant="contained" color="primary" size="large" onClick={handleProductLookup}>
            Product Lookup
          </Button>
          <Button variant="contained" color="secondary" size="large" onClick={handleReset}>
            Reset
          </Button>
        </Box>
      )}

      <Routes>
        <Route path="/survey" element={<SurveyPage />} />
        <Route path="/lookup" element={<ProductLookup />} />
        <Route path="/" element={<Box />} />
      </Routes>
    </Container>
  );
}

export default App;