// src/utils/surveyApi.js
import axios from 'axios';

export async function fetchProduct(barcode, store) {
  try {
    const response = await axios.get(`${process.env.REACT_APP_API_PRODUCT}`, {
      params: { EAN: barcode },
    });
    return response.data?.[0] || { EAN: barcode, ProductDescription: 'Unknown Product' };
  } catch (error) {
    console.error("Error fetching product:", error);
    return { EAN: barcode, ProductDescription: 'Unknown Product' };
  }
}

export async function submitPricingData(products) {
  try {
    await axios.post(`${process.env.REACT_APP_API_PRICING}`, products);
  } catch (error) {
    console.error("Error during submission:", error);
    throw error;
  }
}