// src/components/SurveyPage.js
import React from 'react';
import { Box, Button } from '@mui/material';
import useSurvey from '../hooks/useSurvey';
import LocationAndStore from './LocationAndStore';
import BarcodeScannerComponent from './BarcodeScanner';
import ProductEntry from './ProductEntry';
import SubmitData from './SubmitData';

function SurveyPage() {
  const {
    surveyStarted,
    startNewSurvey,
    handleComplete,
    store,
    handleStoreSelect,
    handleProductScanned,
    handleProductSave,
    handleScanAnother,
    scannedProducts,
    currentProduct,
    showTable,
    scanning,
  } = useSurvey();

  return (
    <Box>
      {!surveyStarted ? (
        <Button variant="contained" color="primary" size="large" onClick={startNewSurvey}>
          Start New Price Survey
        </Button>
      ) : (
        <Box>
          {!store && <LocationAndStore onSelectStore={handleStoreSelect} />}
          {store && !currentProduct && !showTable && (
            <BarcodeScannerComponent onProductScanned={handleProductScanned} scanning={scanning} />
          )}
          {currentProduct && <ProductEntry product={currentProduct} onSave={handleProductSave} />}
          {showTable && (
            <Box>
              <Box display="flex" justifyContent="space-between" mb={3}>
                <Button variant="contained" color="success" onClick={handleComplete}>
                  Submit
                </Button>
                <Button variant="contained" color="secondary" onClick={handleScanAnother}>
                  Scan Another Product
                </Button>
              </Box>
              <SubmitData products={scannedProducts} />
            </Box>
          )}
        </Box>
      )}
    </Box>
  );
}

export default SurveyPage;