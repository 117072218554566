// src/components/SubmitData.js
import React from 'react';

const SubmitData = ({ products }) => {
  return (
    <div>
      <h2>Data Ready for Submission</h2>
      <table className="table table-striped table-responsive">
        <thead className="thead-dark">
          <tr>
            <th>Store Name</th>
            <th>Store Number</th>
            <th>EAN</th>
            <th>Description</th>
            <th>Price</th>
          </tr>
        </thead>
        <tbody>
          {products.map((product, index) => (
            <tr key={index}>
              <td>{product.StoreName}</td>
              <td>{product.LPStoreNumber}</td>
              <td>{product.EAN}</td>
              <td>{product.ProductDescription || 'Unknown Product'}</td>
              <td>{product.Price}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default SubmitData;
